import React from 'react';


const data = [
  { id: 1, date: "2019-20", heading: 'Compliance Report', profileurl: 'https://static.muoninfotech.com/./assets/IQAC/compliance 19-20.pdf' },
  { id: 2, date: "2020-21", heading: 'Compliance Report', profileurl: 'https://static.muoninfotech.com/./assets/IQAC/compliance20-21.pdf' },
  { id: 3, date: "2021-22", heading: 'Compliance Report', profileurl: 'https://static.muoninfotech.com/./assets/IQAC/COMPLIANCE REPORT 21-22.pdf' },
  { id: 3, date: "2023-24", heading: 'Compliance Report', profileurl: 'https://static.muoninfotech.com/./assets/IQAC/ATR 2023-24.pdf' },
];

const ComplianceReport = () => {
  return (
    <div className="container">
      {data.map((item) => (
        <div key={item.id} className="card my-3">
          <div className="card-body">
            <div className="row">
              <div className="col-md-3">
                <p>Year : {item.date}</p>
              </div>
              <div className="col-md-6">
                <h3>{item.heading}</h3>
              </div>
              <div className="col-md-3">
                <button className="btn btn-primary" type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href = item.profileurl;
                  }}>Download</button>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ComplianceReport;
