import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import scroller from '../resources/scroller.json';
import './Scroller.css'; // Import CSS file for styling

const ScrollingText = () => {
  const items = scroller.items;

  const [concatenatedText, setConcatenatedText] = useState('');

  useEffect(() => {
    const updatedText = items.join(' | ');
    setConcatenatedText(updatedText);
  }, [items]);

  return (
    <Container fluid>
      <Row className="no-padding">
        <Col>
          <div className="scrolling-text-container">
            <span> <a href='/admission'></a> {concatenatedText} <a href='/admission'></a></span>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ScrollingText;
