import React from 'react';


const data = [
  { id: 1, date: "2019-20", heading: 'Minutes of the Meeting', profileurl: 'https://static.muoninfotech.com/./assets/IQAC/IQAC MEETINGS 19-20_new.pdf' },
  { id: 2, date: "2020-21", heading: 'Minutes of the Meeting', profileurl: 'https://static.muoninfotech.com/./assets/IQAC/IQAC MEETINGS 20-21_new.pdf' },
  { id: 3, date: "2021-22", heading: 'Minutes of the Meeting', profileurl: 'https://static.muoninfotech.com/./assets/IQAC/IQAC MEETINGS for 21-22_new.pdf' },
   {id:3, date:"2023-2024", heading:"Minutes of the meeting", profileurl:"https://static.muoninfotech.com/./assets/IQAC/minutes of IQAC meetings for 23-24_Updated.pdf"}
];

const MinutesOfMeeting = () => {
  return (
    <div className="container">
      {data.map((item) => (
        <div key={item.id} className="card my-3">
          <div className="card-body">
            <div className="row">
              <div className="col-md-3">
                <p>Year : {item.date}</p>
              </div>
              <div className="col-md-6">
                <h3>{item.heading}</h3>
              </div>
              <div className="col-md-3">
                <button className="btn btn-primary" type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href = item.profileurl;
                  }}>Download</button>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default MinutesOfMeeting;
