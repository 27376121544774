
import React from 'react'
import Table from "../../components/table"
// import data from '../../resources/poco.json'

const ProgramOutcomes = (data) => {
    // const filteredData = data[dept];
  return (
    <div>
    {data  && <Table data={data.programOutcomes} text={"Downlaod"}/>}
    
    
    </div>

  )
}

export default ProgramOutcomes